<template>
  <Auth>
    <section class="card-auth">
      <div class="logo-sesi">
        <ApplicationLogo />
      </div>

      <b-alert
          v-if="alert.show"
          show
          fade
          class="mb-2"
          variant="danger"
      >
        <div class="custom-alert">
                <span>
                  <BIconExclamationCircle />
                  {{ alert.message }}
                </span>
        </div>
      </b-alert>

      <b-card-title
          class="mb-1 font-weight-bold"
          title-tag="h2"
      >
        Olá!
      </b-card-title>
      <b-card-text class="mb-2">
        Por favor, insira os dados da sua conta para acessar nosso portal.
      </b-card-text>

      <b-form
          class="mt-2"
          @submit.prevent="login"
      >
        <!-- email -->
        <b-form-group
            label="E-mail"
            label-for="login-email"
        >

          <b-form-input
              id="login-email"
              v-model="auth.email"
              @focus="alert.show = false"
              name="login-email"
              placeholder="email@email.com"
              autocomplete="off"
          />
          <small class="text-danger">{{ formErrors.email }}</small>
        </b-form-group>

        <!-- forgot password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="login-password">Senha</label>
            <b-link :to="{ name: `auth-forgot-password` }">
              <small>Esqueci minha senha</small>
            </b-link>
          </div>

          <b-input-group
              class="input-group-merge"
          >
            <b-form-input
                id="login-password"
                v-model="auth.password"
                @focus="alert.show = false"
                class="form-control-merge"
                :type="passwordFieldType"
                name="login-password"
                placeholder="******"
                autocomplete="off"
            />
            <b-input-group-append is-text>
              <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ formErrors.password }}</small>

        </b-form-group>

        <button :disabled="disabledButton" type="submit" class="btn button-form btn-block mb-2">
          {{ msgButton }}
        </button>
      </b-form>

      <b-card-text class="text-center margem-div">
        <span>Ainda não é Cliente SESI? </span>
        <br>
        <a href="https://sesisc.org.br/pt-br#block-sesidestaques" target="_blank">
          <span>&nbsp;Descubra nossos produtos!</span>
        </a>
        <hr />
        <b-link :to="{name:'auth-login-admin'}">
          Área administrativa
        </b-link>
      </b-card-text>
    </section>
  </Auth>
</template>

<script>
import Auth from "@/views/pages/authentication/BaseAuth/Auth";
import {
  BIconExclamationCircle,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
  BAlert,
} from 'bootstrap-vue'
import ApplicationLogo from "@/views/components/custom/logo-sesi/ApplicationLogo";
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { validation } from '@core/mixins/validation/validation'
import { messages } from "@core/mixins/validation/messages";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Auth,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BAlert,
    BButton,
    BIconExclamationCircle,
    ApplicationLogo,
  },
  mixins: [togglePasswordVisibility, validation, messages],
  data() {
    return {
      auth: {
        email: '',
        password: '',
      },

      alert: {
        show: false,
        message: '',
        variant: ''
      },

      loadingShow: true,

      disabledButton: false,
      msgButton: 'Entrar',

      formErrors: {},
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    async login() {
      if(this.validation()) {
        this.buttonLoading(true);

        const auth = {
            userData: this.auth,
            admin: false
        }

        await this.$store.dispatch('sessions/login', auth)
            .then((response) => {
              const status = response.request.status;
              const token = response.data.auth.access_token;
              const userData = response.data;

              if(status === 200 && token) {
                this.abilitySessionAndRedirect(userData);
              }
            })
            .catch(error => {
              this.errorHandling(error.response);
            })

        this.buttonLoading(false);
      }
    },

    abilitySessionAndRedirect(userData) {
      if(! userData.ability || userData.ability.length === 0){
        this.$store.dispatch('sessions/logout').then();
        this.$router.replace({ name: 'misc-not-enabled' });

        return false;
      }

      if(userData.status) {
        userData.status = 'active';
      }

      this.$ability.update(userData.ability);

      this.$router.replace({ name: 'bem-vindo'})
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Bem-vindo(a) ${userData.fullName}`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: '',
            },
          })
        })
    },

    errorHandling(response) {
      const status = response.status;
      const message = response.data.error || this.incorrectAuthMsg;

      if(status === 401) {
        this.showAlert(
            message,
            'danger'
        );
      } else {
        this.showAlert(
            this.impossibleMsg,
            'danger'
        );
      }
    },

    validation() {
      this.emailValidate(this.auth.email);

      this.strValidate({
        input: this.auth.password,
        message: this.passwordMsg,
        field: 'password'
      });

      if(this.formValidationRun()) {
        return true;
      } else {
        this.formErrors = {};
        this.formErrors = this.formValidationErrors;

        return false;
      }
    },

    showAlert(message, variant) {
      this.alert.show = true;
      this.alert.message = message;
      this.alert.variant = variant;
    },

    buttonLoading(disabled) {
      if(disabled) {
        this.disabledButton = true;
        this.msgButton = 'Processando...';
      } else {
        this.disabledButton = false;
        this.msgButton = 'Entrar';
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
